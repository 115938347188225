import request from '@/utils/http'

/**
 * 短信运营商
 * @param payload
 */
export function sms_operator(payload) {
    return request({ url: '/sms/index/operator', method: 'post', data: payload })
}

/**
 * 短信测试
 * @param payload
 */
export function sms_send_test(payload) {
    return request({ url: '/sms/index/test', method: 'post', data: payload })
}