<template>
  <div class="main-body">
    <div class="page-attention">
      <p>短信通知：先在短信运营商开通短信服务、设置签名、短信模板。</p>
    </div>
    <a-table
      class="doc-table"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'status'">
          <span v-if="record.status == 1" style="color: #b7eb8f"> 已启用 </span>
          <span v-else style="color: #ffe58f">未启用</span>
        </template>
        <template v-if="column.key === 'sign'">
          【{{ record.sign }}】
        </template>
        <template v-if="column.key === 'action'">
          <a-button type="primary" size="small" class="margin-right15"
            >配置</a-button
          >
          <a-button type="primary" size="small" danger @click="sendTest(record)"
            >测试</a-button
          >
        </template>
      </template>
    </a-table>
  </div>

  <a-modal
    v-model:visible="visible"
    title="发送测试短信"
    :confirm-loading="confirmLoading"
    @ok="testHandleOk"
    @finish="testHandleOk"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      autocomplete="off"
    >
      <a-form-item label="运营商"> {{ formState.title }} </a-form-item>
      <a-form-item label="签名"> {{ formState.sign }} </a-form-item>
      <a-form-item
        label="测试号码"
        name="phone"
        :rules="[{ required: true, message: '请输入测试接收的手机号' }]"
      >
        <a-input v-model:value="formState.phone" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import * as Api from "@/addons/sms/api";
import { message } from "ant-design-vue";

export default defineComponent({
  setup() {
    const dataSource = ref();
    const onLoadData = () => {
      Api.sms_operator().then((res) => {
        dataSource.value = res.data;
      });
    };

    const visible = ref(false);
    const confirmLoading = ref(false);
    const formRef = ref();

    const formState = reactive({
      title: "",
      code: "",
      phone: "",
      sign: "",
    });

    const testHandleOk = () => {
      confirmLoading.value = true;
      formRef.value
        .validate()
        .then(() => {
          Api.sms_send_test(formState).then((res) => {
            console.log(res);
            if (res.status === 200) {
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

      confirmLoading.value = false;
      visible.value = false;
    };

    const sendTest = (record) => {
      formState.title = record.title;
      formState.code = record.code;
      formState.sign = record.sign;
      visible.value = true;
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      formRef,
      formState,
      visible,
      confirmLoading,
      testHandleOk,
      sendTest,
      dataSource,
      columns: [
        {
          title: "运营商",
          dataIndex: "title",
          key: "title",
          width: "20%",
        },
        {
          title: "标识",
          dataIndex: "code",
          key: "code",
          width: "20%",
        },
        {
          title: "签名",
          dataIndex: "sign",
          key: "sign",
          width: "30%",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "20%",
        },
      ],
    };
  },
});
</script>